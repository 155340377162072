@font-face {
    font-family: 'Mabry';
		src: url('../fonts/mabry-light.eot');
    src: url('../fonts/mabry-light.eot?#iefix') format('embedded-opentype'),
         url('../fonts/mabry-light.woff2') format('woff2'),
         url('../fonts/mabry-light.woff') format('woff'),
         url('../fonts/mabry-light.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Mabry';
    src: url('../fonts/mabry-light-italic.eot');
    src: url('../fonts/mabry-light-italic.eot?#iefix') format('embedded-opentype'),
         url('../fonts/mabry-light-italic.woff2') format('woff2'),
         url('../fonts/mabry-light-italic.woff') format('woff'),
         url('../fonts/mabry-light-italic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Mabry';
    src: url('../fonts/mabry-medium.eot');
    src: url('../fonts/mabry-medium.eot?#iefix') format('embedded-opentype'),
         url('../fonts/mabry-medium.woff2') format('woff2'),
         url('../fonts/mabry-medium.woff') format('woff'),
         url('../fonts/mabry-medium.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}


html {
	font-family: 'Mabry', 'Helvetica', sans-serif;
	font-weight: 200;
	font-size: 16px;
	line-height: 22px;
	word-spacing: 0.03em;
}

.small {
	font-size: 0.75rem;
	color: $white;
	
	.dark & {
		color: $black;
	}
	
	@include respond-to(small){
		font-size: 0.55rem;
	}
}

.caps {
	text-transform: uppercase;
	letter-spacing: 0.075em;
}

.medium {
	font-size: 1.15rem;
	line-height: 1.65em;
	max-width: 750px;
	color: $white;
	
	.dark & {
		color: $black;
	}
	
	@include respond-to(small){
		font-size: 0.9rem;
	}
}

.large {
	font-size: 2rem;
	line-height: 1.333em;
	max-width: 750px;
	color: $white;
	
	.dark & {
		color: $black;
	}
	
	@include respond-to(small){
		font-size: 1.5rem;
		
		&:first-child {
			margin-top: 0;
		}
	}
}
.x-large {
	font-size: 3rem; 
	
	@include respond-to(small){
		font-size: 2rem;
	}
}

h1 {
	@extend .large;
}

h2 {
	@extend .large;
	strong {
		font-weight: 600;
	}
	&.project-title {
		margin-bottom: 0;
	}
}

h3, p {
	@extend .medium;
}

p {
	&.project-commission {
		margin-top: 0;
		color: rgba($white, 0.5);
		
		.dark & {
			color: rgba($black, 0.5);
		}
	}
}


blockquote {
	@extend .medium;
	font-style: italic;
	&::before {
		content: "\201C";
		margin-left: -0.45rem;
	}
	
	@include respond-to(small){
		margin: 4vw;
	}
}

dl {
	dt {
		@extend .small;
		@extend .caps;
		
		color: rgba($white, 0.5);
		margin: 0;
		padding: 0;
		
		.dark & {
			color: rgba($black, 0.5);
		}
	}
	dd {
		@extend .medium;
		color: $white;
		margin: 0 0 0.5rem 0;
		padding: 0;
		
		.dark & {
			color: $black;
		}
	}
}

ul {
	@extend .medium;
}

a {
	color: $white;
	text-decoration: none;
	border-bottom: 1px rgba(255, 255, 255, 0.6) solid;
	padding-bottom: 1px;
	transition: border-color 0.25s ease-in-out;
	
	&:hover {
		border-color: rgba(255, 255, 255, 0.0);
	}
	
	.dark & {
		color: $black;
		border-color: rgba($black, 0.6);
		&:hover {
			border-color: rgba($black, 0.0);	
		}
	}
	

}