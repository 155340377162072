$black: #000;
$dark-grey: #333333;
$grey: #c3c3c3;
$white: #ffffff;

$break-small: 900px;
$break-large: 1100px;

@mixin respond-to($media) {
  @if $media == small {
    @media only screen and (max-width: $break-small) { @content; }
  }
  @else if $media == medium {
    @media only screen and (min-width: $break-small + 1) and (max-width: $break-large - 1) { @content; }
  }
  @else if $media == wide {
    @media only screen and (min-width: $break-large) { @content; }
  }
}