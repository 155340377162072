/* Plyr video player overrides */

.plyr__control{
	border-radius: 50%;
	padding: 8px;
}

.plyr__control--overlaid{
	background: rgba(255, 255, 255, 0.5) !important;
	padding: 16px;
	transform: translate(-50%, -50%) scale(1.5);
}

.plyr--video .plyr__control.plyr__tab-focus, 
.plyr--video .plyr__control:hover 
{
	background: rgba(255, 255, 255, 0.5) !important;
}

.plyr--full-ui input[type=range]
{
	color: #ffffff;
}

/* Plyr audio player overrides */

.tile.music {
	flex-direction: column;
	justify-content: space-between;
	
	.plyr--audio {
		
		@include respond-to(small){
			padding: 3vw;
		}
		
		.plyr__control {
			padding: 18px;
			background: rgba(255, 255, 255, 0.1);
			&:hover {
				background: $black;	
			}	
		}
	
		.plyr__controls {
			background: transparent;
			padding: 0;
			
			.icon--not-pressed {
				fill: #ffffff;
			}
			
			.icon--pressed {
				fill: #ffffff;
			}
		}
		
		.plyr__progress__buffer {
			color: rgba(255, 255, 255, 0.4);
			background: transparent;
		}
		
		.plyr__time {
			color: #ffffff;
		}
	}
}


/* Homepage gallery */

#gallery-loop {
	position: fixed;
	display: block;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: -1;
	transition: opacity 1.0s ease;
	opacity: 1.0;
	
	&.hide {
		opacity: 0.0;
	}
	
	figure {
		position: absolute;
		display: block;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		top: 0; 
		left: 0;
		width: 100%;
		height: 100%;
		margin: 0;
		padding: 0;
	}

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		margin: 0;
		padding: 0;
		object-fit: cover;
	}
}

/* Homepage nav */

.home nav {
	position: absolute;
	top: 50%;
	transform: translate(0, -50%);
	
	@include respond-to(small){
		position: static;
		transform: none;
	}
}