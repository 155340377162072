html {
	background: $black;
	overflow-x: hidden;
}

$overallWidth: 100.0vw;
$verticalPadding: 1.5vw;
$horizontalPadding: 3vw;

nav {
	width: #{$overallWidth - $horizontalPadding * 2};
	padding: $verticalPadding $horizontalPadding;
	margin-bottom: $verticalPadding * 2;
	z-index: 100;
	position: relative;
	
	h1, a {
		@extend .medium;
		margin: 0;
		font-weight: 500; 
		color: $white;
		border: 0;
	}
	
	h1 {
		position: relative;
		left: 0;
	}
	
	ul {
		position: absolute;
		top: $verticalPadding;
		right: $horizontalPadding;
		list-style: none;
		margin: 0;
		text-align: right;
		
		li {
			float: left;
			display: block;
			
			a {
				display: block;
				text-decoration: none;
				margin-right: 1.5rem;	
			}
		}
	}
	
	@include respond-to(small){
		z-index: 100;
		padding: $verticalPadding * 4;	
		min-height: 20vh;
		width: auto;
		
		ul {
			top: $verticalPadding * 4;
			left: 50%;
			right: auto;
			align-self: flex-start;
			text-align: left;
			
			li {
				width: 100%;
			}
		}	
	}
}

footer {
	position: relative;
	width: #{$overallWidth - $horizontalPadding * 2};
	padding: $verticalPadding $horizontalPadding;
	display: flex;
	justify-content: space-between;
		
	@include respond-to(small){
		width: auto;
		flex-direction: column-reverse;
		padding: $verticalPadding * 4 $horizontalPadding * 2;
	}
	
	p {
		@extend .small;
		flex: 0 1 60%;
		margin: 0;
		padding: 0;
		color: rgba(255, 255, 255, 0.6);
		max-width: none;
		
		@include respond-to(small){
			margin: $horizontalPadding * 1.5 0 0 0;
		}
	}
	
	.home &, .not-found & {
		position: absolute;
		bottom: 0;
		
		@include respond-to(small){
			position: absolute;
		}
	}
	
	ul {
		@extend .small;
		flex: 0 1 auto;
		align-self: flex-end;
		list-style: none;
		margin: 0;
		
		@include respond-to(small){
			padding: 0;
			height: 20px;
			display: block;
			align-self: flex-start;
		}

		li {
			float: left;
			margin-right: 15px;
			height: #{20 * 1.5}px;	
			color: $grey;	

			a {
				width: 20px;
				height: 20px;
				overflow: hidden;
				display: inline-block;
				text-indent: -999px;
				border: none;
				background: transparent url("../img/icons.svg") left center no-repeat;
				background-size: 80px 40px;
				background-position-y: 0;
				transform: scale(1.5);
				
				.dark & {
					background-position-y: -20px;	
				}
				
				&.email {	
				}
				&.soundcloud {
					background-position-x: -20px;
				}
				&.instagram {
					background-position-x: -40px;
				}
				&.vimeo {
					background-position-x: -60px;
				}
			}
		}
	}
}

.secondary {
	p {
		color: $grey !important;	
	}
}

.grid {
	width: #{$overallWidth - $horizontalPadding * 2};
	padding: 0 $horizontalPadding $horizontalPadding $horizontalPadding;
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	
	@include respond-to(small){
		width: #{$overallWidth - $horizontalPadding * 4};
		padding: 0 $horizontalPadding*2 $horizontalPadding $horizontalPadding*2;
	}

	&.first {
		padding-top: $horizontalPadding;	
	}
	
	&.set {
		flex-flow: wrap;
	}
	
	.column {
		flex: 0 1 50%;
		display: flex;
		flex-direction: column;
		align-content: flex-start;
		
		@include respond-to(medium){
			flex: 0 1 100%;	
		}
		@include respond-to(small){
			flex: 0 1 100%;	
		}
	}
	
	.row {
		flex: 0 1 100%;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-content: center;
		justify-content: space-between;
		margin-bottom: 2.5vw;
		
		@include respond-to(small){
			margin-bottom: 0;
			margin-top: 10vw;
		}
		
		&[data-assets="2"]{
			img {
				flex: 0 1 49.5%;
				width: 49.5%;
			}
		}
		
		&[data-assets="3"]{
			img {
				flex: 0 1 32.6%;
				width: 32.6%;
			}
		}
		
		&[data-assets="4"]{
			img {
				flex: 0 1 49.5%;
				width: 49.5%;
				margin-top: 1vw;
			}
		}
		
		img {
			align-self: center;
			flex: 0 1 100%;
			width: 100%;
			height: auto;
		}
		
		.video-container {
			width: 100%;
		}
		
		&.fit-to-height {
			align-content: center;
			justify-content: center;
			flex-wrap: wrap;
			
			img {
				flex: 0 1 auto;
				width: auto;
				height: 90vh;
				margin: 0 1%;
				
				@include respond-to(small){
					width: 100%;
					height: auto;
				}
			}	
			
			.video-container {
				height: 100vh;
				width: auto;
			}		
		}
	}
	
		
	p.caption {
		@extend .small;
		margin: -1% auto 5% auto;
		text-align: center;
		
		@include respond-to (small){
			margin: 4vw 8vw 0 0;
			text-align: left;
		}
	}

	.wide-column {
		flex: 0 1 40%;
		margin-right: 10%;	
		
		@include respond-to(medium){
			flex: 0 1 100%;
			margin-right: 0%;
		}	
		@include respond-to(small){
			flex: 0 1 100%;
			margin-right: 0%;
		}		
	}

	.full {
		padding-top: 2.5vw;
		width: 100%;
		height: auto;
		
		@include respond-to(small){
			width: 100%;	
		}
	}
	
	.half {
		padding-top: 2.5vw;
		width: 33%;
		height: auto;
		
		@include respond-to(small){
			width: 50%;	
		}
	}
	
	.tile {
		flex: 0 0 #{(91%)/3};
		margin: 0.5% 0.5% 0 0;
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		min-height: #{(91vw)/3};
		background: rgba($grey, 0.2);
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		border: 0 !important;
		padding: 1vw;
		
		@include respond-to(small){
			flex: 0 0 100%;
			min-height: 105vw;
			background-position-y: 10vw;
			padding: 0;
			margin-bottom: 12vw;
			
			h3, p {
				width: 100%;
				opacity: 1.0 !important;
				background: $black;
				padding-left: 0;
				&:last-child {
					padding-bottom: $verticalPadding;
				}
			}
		}	
		
		&:hover {
			&::before {
				background: rgba(0, 0, 0, 0.5);
			}
			h3, p {
				opacity: 1.0;
			}
		}
		
		&::before {
			content: "";
			background: rgba(0, 0, 0, 0.0);
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			transition: background 0.3s ease;
		}

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		
		h3 {
			@extend .medium;
			z-index: 1;
			align-self: flex-start;
			margin: 0;
			opacity: 0.0;
			transition: opacity 0.3s ease;
		}
		
		p {
			@extend .medium;
			z-index: 1;
			align-self: flex-start;
			margin: 0;
			opacity: 0.0;
			transition: opacity 0.3s ease;
		}
		
		&.music {
			min-height: #{(92vw)/3};
			
			@include respond-to(small){
				flex: 0 1 100%;
				min-height: 118vw; 
			}
			
			&:hover {
				&::before {
					background: transparent;
				}
			}

			h3, p {
				opacity: 1.0;
			}
		}
	}
}

.bw {
	filter: grayscale(100%);
}